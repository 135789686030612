@use './kindertons.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

app-navigation {
  router-outlet+* {
    flex: 1;
  }
}

.display-flex {
  display: flex;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-row-between {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch space-between;
  align-items: stretch;
}

.flex-20 {
  width: 20%;
}

.flex-30 {
  width: 30%;
}

.flex-40 {
  width: 40%;
}

.flex-50 {
  width: 50%;
}

.flex-60 {
  width: 60%;
}

.flex-70 {
  width: 70%;
}

.flex-100 {
  width: 100%;
}

.search-group {
  justify-content: right;
  float: right;
}

.invisible-form-field .mat-form-field-underline {
  background-color: transparent;
}

.invisible-form-field .mat-form-field-infix {
  width: auto;
}

.error-only-form-field .mat-form-field-flex {
  display: none;
}

input {
  text-overflow: ellipsis;
}

input[readonly] {
  color: rgba(0, 0, 0, .38);
}

.mat-accordion>.mat-expansion-panel-spacing:last-child {
  margin-bottom: 15px !important;
}

.mb-expansion {
  margin-bottom: 15px;
}

.btn-confirm {
  left: 6%;
}

.btn-table-row {
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  min-height: 36px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.colour-success {
  color: #28a745;
}

.colour-danger {
  color: #bd2130;
}

.not-found-text {
  margin-left: 2rem;
  margin-top: 1.1rem;
  font-weight: bold;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}

.mat-mdc-checkbox-layout {
  white-space: normal !important;
}

.mat-mdc-radio-label {
  white-space: normal !important;
}

.mat-mdc-checkbox-inner-container {
  margin-top: 4px !important;
}

.custom-tooltip {
  max-width: unset !important;
}

.mat-mdc-button>.mat-icon {
  height: 24px !important;
  width: 24px !important;

  margin-right: 2px !important;
}

.btn-add,
.btn-create {
  .mat-icon {
    height: 1.125rem !important;
  }
}

.mat-mdc-tab.mdc-tab {
  letter-spacing: normal;
}

td .mat-mdc-button>.mat-icon {
  margin-right: 0px !important;
  height: 1.5rem !important;
  width: 1.6rem !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: transparent !important;
}

.mat-form-field-appearance-fill {
  .mdc-floating-label {
    padding-top: 12px;
  }
}

.mat-mdc-form-field {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mdc-text-field--filled {
    background-color: transparent;
  }

  .mdc-text-field {
    padding: 0;
  }

  .mat-mdc-form-field-infix {
    padding-bottom: 0 !important;
    min-height: 40px !important;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-hint-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mdc-floating-label--required {
    margin-left: 5px;
  }

  .mat-mdc-form-field-error {
    line-height: 15px;
  }

  .mat-mdc-form-field-hint {
    line-height: 15px;
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-datepicker-toggle {
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
      margin-top: 12px;
      margin-left: 6px;
      width: 32px;
      height: 32px;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      width: 44px;
      height: 44px;
      padding: 16px 0 0
    }
  }
}

.mat-list-item {
  padding: 8px 0;
}

.mdc-list-item__content {
  padding-left: 10px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}

mat-card {
  margin: 1rem;
}

.mat-form-field-appearance-outline {
  .mdc-text-field {
    padding: 0 10px 0 10px;
  }
}

.mat-mdc-header-cell {
  color: #0000008a;
  border-bottom-color: #0000001f;
  font-size: 12px;
  font-weight: 500;
}

.mat-sort-header-content {
  color: #0000008a;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: #000000de;
}

.mat-mdc-card-subtitle {
  font-size: 14px !important;
  margin-bottom: 1rem !important;
}

div.mat-mdc-select-panel {
  max-height: 300px !important;
}

mat-dialog-actions {
  color: #000000de !important;
}

.mdc-switch--selected .mdc-switch__icon--on,
.mdc-switch--unselected .mdc-switch__icon--off {
  opacity: 0 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}

.history-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0px;
  }
}

.mat-mdc-raised-button {
  height: 36px !important;
}
